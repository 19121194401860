.App {
  text-align: center;
}

.edit-btn{
  margin:2% 0% !important;
}
.box-container{
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(235, 234, 234);
  border-radius: 15px;
  box-shadow: 4px 4px 8px 1px rgb(149, 148, 148);
  margin: 1% 15%;
  padding: 2% 6%;
}
.profile-container{
  display: flex;
  flex-direction:column;
  justify-content: space-evenly;
  align-items: center;
}


.image{
  border: 7px solid  rgb(240, 236, 236);
}
.navbar{
  display: flex;
  align-self: center;
  justify-content: space-between;
  background-color: rgb(34, 34, 80);
  font-size: 1.2rem;
  padding: 1%;
  margin-bottom: 3%;
  color:white;
}

a{
 color:white;
  text-decoration: none;
}
a:hover{
  color: red;
}
.register{
  color:blue;
}
